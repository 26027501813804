<template>
  <ion-modal :is-open="isQRCodeModalOpen">
    <ion-header v-if="!isScannerRunning">
      <ion-toolbar>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">{{ $t('interface.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="scan-box" v-if="isScannerRunning"></div>
      <template v-else>
          <div class="content-result">
            <the-loader v-if="loading"/>

            <template v-else>
              <template v-if="qrCodeSuccessfullyScaned">
                <ion-icon slot="end" :md="checkmarkOutline" :ios="checkmarkOutline" style="font-size: 70px;" color="success"></ion-icon>
                <ion-text style="margin-top: 5px;">
                  <h5>{{ $t('qrCodeScannedSuccessfully') }}</h5>
                </ion-text>
              </template>

              <div class="error-message" v-else-if="theoryAttendanceError.length > 0">
                <ion-icon slot="end" :md="closeOutline" :ios="closeOutline" style="font-size: 70px;"></ion-icon>
                <ion-text style="margin-top: 5px;text-align: center;">
                  <h5>{{ theoryAttendanceError }}</h5>
                </ion-text>
                <ion-button expand="block" @click="startScan">{{ $t('interface.scanQRCode') }}</ion-button>
              </div>

              <div class="error-message" v-else>
                <ion-icon slot="end" :md="qrCodeOutline" :ios="qrCodeOutline" style="font-size: 70px;"></ion-icon>
                <ion-text style="margin-top: 5px;text-align: center;">
                  <h5>{{ statusMessage }}</h5>
                </ion-text>
                <ion-button expand="block" @click="startScan">{{ $t('interface.scanQRCode') }}</ion-button>
              </div>
            </template>
         </div>
      </template>
      <div class="action-buttons" v-if="isScannerRunning">
        <ion-grid>
            <ion-row>
                <ion-col size-sm="2" size-xs="3">
                    <ion-button expand="block" @click="toggleCameraDirection">
                        <ion-icon :md="cameraReverseOutline" :ios="cameraReverseOutline" style="font-size: 30px;"></ion-icon>
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button expand="block" @click="close">{{ $t('interface.close') }}</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="js">
import api from "../services/Api";

import { useI18n } from 'vue-i18n'
import { defineComponent, onMounted, onUnmounted, ref, computed } from 'vue';

import TheLoader from "@/components/TheLoader.vue";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonText,
  IonIcon,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonGrid
} from "@ionic/vue";
import { BarcodeScanner, SupportedFormat, CameraDirection } from '@capacitor-community/barcode-scanner';
import { checkmarkOutline, closeOutline, qrCodeOutline, cameraReverseOutline } from "ionicons/icons";

import {Auth} from "@/services/AuthService";
import store from "@/store";

export default defineComponent({
  emits: ['start', 'stop'],
  setup(props, { emit }) {
    const { t }  = useI18n()
    const loading = ref(false);
    const isQRCodeModalOpen = ref(false);
    const isScannerRunning = ref(false);
    const qrCodeSuccessfullyScaned = ref(false);
    const theoryAttendanceError = ref('');
    const cameraDirection = ref(CameraDirection.BACK);
    const permissionDenied = ref(false)
    const permissionAsked = ref(false)

    const statusMessage = computed(() => {
      if (! permissionAsked.value && ! permissionDenied.value || permissionAsked.value) return t('allowTheCameraAccess');

      if (permissionDenied.value) return t('youNeedToAllowTheCamera')

      return t('startingCamera')
    })

    const didUserGrantPermission = async () => {
  // check if user already granted permission
      const status = await BarcodeScanner.checkPermission({ force: false });

      if (status.granted) {
        // user granted permission
        permissionDenied.value = false
        return true;
      }

      if (status.denied) {
        permissionDenied.value = true
        // user denied permission
        const c = confirm(t('askCameraPermissionSettings'))

        if (c) {
          BarcodeScanner.openAppSettings();
        }

        return false;
      }

      if (status.asked) {
        permissionAsked.value = true

        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (status.neverAsked) {
        // user has not been requested this permission before
        // it is advised to show the user some sort of prompt
        // this way you will not waste your only chance to ask for the permission
        permissionAsked.value = false
        permissionDenied.value = true
        const c = confirm(t('askCameraPermissionSettings'));
        if (!c) {
          return false;
        }
      }

      if (status.restricted || status.unknown) {
        // ios only
        // probably means the permission has been denied
        permissionAsked.value = true
        permissionDenied.value = true
        return false;
      }

      // user has not denied permission
      // but the user also has not yet granted the permission
      // so request it
      const statusRequest = await BarcodeScanner.checkPermission({ force: true });

      if (statusRequest.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (statusRequest.granted) {
        permissionAsked.value = true
        permissionDenied.value = false
        // the user did grant the permission now
        return true;
      }

      // user did not grant the permission, so he must have declined the request
      return false;
    };

    const startScan = async () => {
      resetDialog()

      // Check camera permission
      // This is just a simple example, check out the better checks below
      const permitted = await didUserGrantPermission();

      if (! permitted) {
        return false
      }

      // make background of WebView transparent
      // note: if you are using ionic this might not be enough, check below
      await BarcodeScanner.hideBackground();
      document.querySelector('body').classList.add('scanner-active');

      isScannerRunning.value = true

      emit('start')
      // start scanning and wait for a result
      const result = await BarcodeScanner.startScan(
          {
              targetedFormats: [SupportedFormat.QR_CODE],
              cameraDirection: cameraDirection.value
          }
      );

      // if the result has content
      if (result.hasContent) {
        stopScan();
        if (props.type === 'login-with') {
            let username = null;
            let password = null;

            try {
                const url = new URL(result.content);
                const searchParams = new URLSearchParams(url.search);

                if (!searchParams.get('username') || !searchParams.get('password')) {
                    throw new Error('No username or password provided');
                }

                username = searchParams.get('username')
                password = searchParams.get('password')
            } catch (e) {
                console.error(e.message)
                theoryAttendanceError.value = t('messages.invalidTokenProvided')
                return
            }

            try {
                await Auth.Instance.signIn({ username, password })
            } catch (e) {
                theoryAttendanceError.value = e.message
            }

            close()
        }

        if (props.type === 'theory-attendance') {
          loading.value = true
          api
            .post('lessonattendance', { qrCodeGuid: result.content })
            .then(() => {
                qrCodeSuccessfullyScaned.value = true
            })
            .catch(error => {
                const response = error.response || {}
                const responseData = response.data || {}

                let errorMessage = responseData.message ?? ''
                if (responseData.errors) {
                    const errorsKeys = Object.keys(responseData.errors).reverse()
                    errorMessage = responseData.errors[errorsKeys[0]] ? responseData.errors[errorsKeys[0]][0] : ''
                }

                theoryAttendanceError.value = errorMessage || t('messages.somethingWentWrong')
            })
            .finally(() => {
                loading.value = false
            })
        }
      }
    };

    const stopScan = () => {
      isScannerRunning.value = false

      emit('stop')

      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      document.querySelector('body').classList.remove('scanner-active');
    };

    const resetDialog = () => {
        theoryAttendanceError.value = ''
        qrCodeSuccessfullyScaned.value = false
    }

    const open = () => {
      isQRCodeModalOpen.value = true

      resetDialog()
      startScan()
    }

    const close = () => {
      isQRCodeModalOpen.value = false
      store.commit('closeQRScanner')

      stopScan()
    }

    const toggleCameraDirection = async () => {
        cameraDirection.value = cameraDirection.value === CameraDirection.FRONT ? CameraDirection.BACK : CameraDirection.FRONT

        await BarcodeScanner.startScan(
          {
              targetedFormats: [SupportedFormat.QR_CODE],
              cameraDirection: cameraDirection.value
          }
        );
    }

    onMounted(() => {
      document.addEventListener('ionBackButton', close);
    })

    onUnmounted(() => {
      document.removeEventListener('ionBackButton', close);
    })

    return {
      isQRCodeModalOpen,
      isScannerRunning,
      loading,
      theoryAttendanceError,
      qrCodeSuccessfullyScaned,
      startScan,
      stopScan,
      open,
      close,
      checkmarkOutline,
      closeOutline,
      qrCodeOutline,
      cameraReverseOutline,
      toggleCameraDirection,
      statusMessage,
      permissionDenied,
      permissionAsked
    }
  },
  name: "QRCodeScannerModal",
  components: {
    TheLoader,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonText,
    IonCol,
    IonRow,
    IonGrid
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'theory-attendance'
    }
  },
})
</script>

<style scoped lang="scss">
.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center
}
.content-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 10%;
}
.error-message {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scan-box {
  border: 1px solid #fff;
  border-radius: 10%;
  box-shadow: 0 0 0 100vmax rgb(0, 0, 0, 0.5);
  content: "";
  display: block;
  left: 50%;
  height: 300px;
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 300px;
  z-index: 1;
}
.action-buttons {
  position: absolute;
  padding: 0 10px;
  width: 100%;
  z-index: 2;
  bottom: 10px;
}
</style>
