import {createStore} from "vuex";
import {AxiosError, AxiosResponse} from "axios";
import api from "@/services/Api.js";

interface ISchoolInfo {
    city: string,
    email: string,
    facebook: string,
    instagram: string,
    logo: string,
    name: string,
    phone: string,
    snapChat: string,
    street: string,
    tikTok: string,
    website: string,
    youtube: string,
    zipCode: string
}

interface IStore {
    isAuthenticated: undefined,
    user: {
        name: string | null,
        username: string | null,
        email: string | null,
        cloudApiUrl: string | null,
    },
    schoolLoading: boolean,
    school: ISchoolInfo,
    toastMessage: {
        show: boolean,
        duration: number,
        title: string,
        message: string,
        action: string | null,
        args: string
    },
    QRScanner: {
        show: boolean,
        title: string,
        type: string
    }
}

export default createStore({
    state (): IStore {
        return {
            isAuthenticated: undefined,
            user: {
                name: null,
                username: null,
                email: null,
                cloudApiUrl: null,
            },
            schoolLoading: true,
            school: {
                city: '',
                email: '',
                facebook: '',
                instagram: '',
                logo: '',
                name: '',
                phone: '',
                snapChat: '',
                street: '',
                tikTok: '',
                website: '',
                youtube: '',
                zipCode: ''
            },
            toastMessage: {
                show: false,
                duration: 0,
                title: '',
                message: '',
                action: null,
                args: ''
            },
            QRScanner: {
                show: false,
                title: '',
                type: ''
            }
        }
    },
    actions: {
        loadSchoolInfo({ commit }) {
            return new Promise((resolve, reject) => {
                commit('setSchoolLoadingStatus', true)
                api
                    .get('drivingschool')
                    .then((response: AxiosResponse) => {
                        commit('setSchoolInfo', response.data)
                        resolve(response.data)
                    })
                    .catch((error: AxiosError) => reject(error))
                    .finally(() => commit('setSchoolLoadingStatus', false))
            })
        }
    },
    mutations: {
        setAuthState (state, authState) {
            state.isAuthenticated = authState
        },
        setUserInfo (state, userInfo) {
            state.user = userInfo;
        },
        setSchoolLoadingStatus(state, status) {
            state.schoolLoading = status
        },
        setSchoolInfo(state, schoolInfo: ISchoolInfo) {
            const keys= Object.keys(state.school) as (keyof ISchoolInfo)[]
            keys.forEach(key => state.school[key] = schoolInfo[key])
        },
        showQRScanner (state, { title, type }) {
            state.QRScanner.show = true
            state.QRScanner.title = title || ''
            state.QRScanner.type = type || ''
        },
        closeQRScanner (state) {
            state.QRScanner.show = false
            state.QRScanner.title = ''
            state.QRScanner.type = ''
        },
        showToast(state, { title, message, duration, action, args }) {
            state.toastMessage.show = true
            state.toastMessage.title = title
            state.toastMessage.message = message
            state.toastMessage.duration = duration || 3000
            state.toastMessage.action = action || null
            state.toastMessage.args = args || ''
        },
        closeToast(state) {
            state.toastMessage.show = false
        }
    }
})
